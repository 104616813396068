// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

const useStyles = makeStyles(styles);

export default function AboutMeSection() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={2} md={2} mx={"auto"} />
      <GridItem xs={12} sm={2} md={2} mx={"auto"}>
        <h4 className={classes.title}>Skills</h4>
        <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
          <li>PHP</li>
          <li>Javascript</li>
          <li>Typescript</li>
          <li>Go</li>
          <li>Laravel / Lumen</li>
          <li>Docker</li>
          <li>AWS</li>
          <li>MYSQL</li>
          <li>Terraform</li>
          <li>Serverless</li>
          <li>Apache / Nginx</li>
          <li>Git</li>
          <li>CI/CD</li>
          <li>TDD</li>
          <li>Linux</li>
        </ul>
      </GridItem>
      <GridItem xs={12} sm={6} md={6}>
        <h4 className={classes.title}>About Me</h4>
        <p>
          I’ve 9 years commercial experience with LAMP stack,
          Javascript/Typescript and GoLang with various frameworks such as
          Laravel, Lumen, NodeJS, React. I’m very passionate about learning and
          teaching new technologies. During my career, I’ve led small tech
          teams, decided and designed software architect in corporation with
          DevOps and developed greenfield products. I am able to take
          responsibility as well as clearly delegate when it’s necessary.
        </p>
        <p>
          I worked within agile environments to deliver testable, secure and
          maintainable code with test-driven development approach. I have been
          using AWS technologies, Docker with continuous integration and
          deployment strategies. I’ve gained deep knowledge in multi-tenant SAAS
          products, event sourcing, credit card processing, 3rd party API
          integrations and PCI DSS standards within FinTech, and E-Learning
          industries in my previous experiences.
        </p>

        <p>
          In my recent role, I’ve gain experience on Serverless Architecture
          with GoLang and AWS AppSync.
        </p>
      </GridItem>
      {/* <GridItem xs={12} sm={2} md={2} mx={"auto"} /> */}
    </GridContainer>
  );
}
