// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CloudDownload } from "@material-ui/icons";
import EmailIcon from "@material-ui/icons/Mail";
import profile from "assets/img/profile.jpg";
import styles from "assets/jss/material-kit-pro-react/views/profilePage.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

const useStyles = makeStyles(styles);

export default function HeroSection() {
  const classes = useStyles();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={6}>
        <div className={classes.profile}>
          <div>
            <img
              src={profile}
              alt="Muharrem Tigdemir"
              className={imageClasses}
            />
          </div>
          <div className={classes.name}>
            <h3 className={classes.title}>Muharrem Tigdemir</h3>
            <h6>Software Engineer</h6>
            <Button
              justIcon
              link
              className={classes.margin5}
              href="https://uk.linkedin.com/in/mtigdemir"
              target="_blank"
            >
              <i className={"fab fa-linkedin"} />
            </Button>
            <Button
              justIcon
              link
              className={classes.margin5}
              href="https://twitter.com/mtgdemir"
              target="_blank"
            >
              <i className={"fab fa-twitter"} />
            </Button>
            <Button
              justIcon
              link
              className={classes.margin5}
              href="mailto:muharrem.tigdemir@gmail.com?Subject=Hello"
              target="_blank"
            >
              {/* <i className={"fab fa-envelope"} /> */}
              <EmailIcon />
            </Button>
            <Button
              justIcon
              link
              className={classes.margin5}
              href="https://github.com/mtigdemir"
              target="_blank"
            >
              <i className={"fab fa-github"} />
            </Button>
            <Button
              justIcon
              link
              className={classes.margin5}
              href="https://medium.com/@mtigdemir"
              target="_blank"
            >
              <i className={"fab fa-medium"} />
            </Button>
            <Button
              justIcon
              link
              className={classes.margin5}
              href="https://www.goodreads.com/review/list/45693918-muharrem-tigdemir?shelf=tech&view=covers"
              target="_blank"
            >
              <i className={"fab fa-goodreads"} />
            </Button>
          </div>
        </div>
      </GridItem>
      <GridItem>
        <div className={classes.description}>
          <p>
            Proud to be Software Engineer & Founder of Enigma Soft Limited, Ney
            Player and Father
          </p>
          <p>Living in UK and keep travel.</p>
          <Button color="danger" round href="./resume_muharrem_tigdemir.pdf">
            <CloudDownload /> Download Resume
          </Button>
        </div>
      </GridItem>
    </GridContainer>
  );
}
